<template>
  <div class="text-center">
    <v-dialog
      @click:outside="setDialog()"
      @keydown.esc="setDialog()"
      v-model="dialog"
      width="650"
    >
      <v-card>
        <v-toolbar dark dense class="white--text" flat>
          <v-toolbar-title class="font-weight-regular my-4"
            >Leads seleccionados</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="setDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- Loading Data -->
        <div v-if="loading" class="text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <!-- Error view -->
        <div v-else-if="!leads.length">
          <div class="text-center">
            <p class="text-h4 text--primary">
              UPS!!!
            </p>
            <p class="gray-text">
              Hubo un error al buscar los leads, Intentelo de nuevo mas tarde
            </p>
          </div>
          <v-img
            class="mx-auto"
            lazy-src="https://res.cloudinary.com/capital28-investments/image/upload/v1640032741/Capital%2028/Common/no_result_lead.png"
            max-height="210"
            max-width="260"
            src="https://res.cloudinary.com/capital28-investments/image/upload/v1640032741/Capital%2028/Common/no_result_lead.png"
          >
          </v-img>
        </div>
        <!-- List View -->
        <v-card-text v-else>
          <v-list subheader>
            <v-list-item
              v-for="item in leads"
              class="itemStyle"
              :key="item._id"
              @click="setActualLead(item._id)"
            >
              <v-list-item-avatar size="25">
                <v-img :src="item.source"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  item.contact_lead_name
                }}</v-list-item-title>

                <v-list-item-subtitle v-if="item.budget > 0"
                  >Presupuesto:

                  <span class="green--text text--darken-4">
                    {{ budgetFormat(item) }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Creado: {{ formatDate(item.created_at) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Actualizado: {{ formatDate(item.updated_at) }}
                </v-list-item-subtitle>
                <span
                  v-if="item.discard_observations"
                  class="pt-1 font-weight-medium"
                >
                  Motivo de descarte: {{ item.discard_observations }}
                </span>
              </v-list-item-content>

              <v-list-item-icon class="mx-2" justify="space-between">
                <lead-postpone-action
                  :postponed="item.postponed?.date_at"
                  :leadId="item._id"
                  :lead="item"
                />
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="setDialog()">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <lead-detail-modal
      ref="leadDetailModal"
      page="table"
      :dialog="showDetail"
      @setDialog="
        value => {
          showDetail = value;
        }
      "
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import leadDetailModal from "../../lead/LeadDetailModal";
import leadPostponeAction from "../../lead/LeadPostpone";
export default {
  name: "ListLeadsByReportDetail",
  components: {
    leadDetailModal,
    leadPostponeAction
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    leads: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDetail: false,
      money: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0
      })
    };
  },
  methods: {
    ...mapActions({
      fetchLead: "leads/fetchActualLead"
    }),
    ...mapMutations({
      setLoadingActualLead: "leads/setLoading"
    }),
    setDialog() {
      this.$emit("setDialog", false);
    },
    async setActualLead(leadId) {
      this.setLoadingActualLead(true);
      this.showDetail = true;

      this.$refs.leadDetailModal.getTrafficData();
      await this.fetchLead(leadId);

      this.setLoadingActualLead(false);
    },
    budgetFormat(item) {
      if (item.budget === 0) {
        return "-";
      } else {
        return (
          this.money.format(item.budget) +
          " " +
          (item.currency ? item.currency : "MXN")
        );
      }
    },
    formatDate(date) {
      if (!date) return "-";
      return new Date(date).toLocaleDateString("es-MX", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      });
    }
  }
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
  padding: 0px;
}
.itemStyle:hover {
  background: #e0e0e0;
}
</style>
